import React from "react";
import DocumentManager from "../components/DocumentManager";

const Documents = () => {
  return (
    <div className="documents-page">
      <DocumentManager />
    </div>
  );
};

export default Documents;
