import React from "react";
import { useNavigate } from "react-router-dom";

function CTASection() {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    window.open("https://app.pachira.abandm.com/register", "_blank");
  };

  const handleContactClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <div className="cta-section">
      <h2>Join Pachira Today</h2>
      <p>
        Take control of your finances with Pachira. Track your expenses, set
        savings goals, and visualize your financial health in real-time.
      </p>
      <div className="cta-buttons">
        <button className="button contained" onClick={handleRegisterClick}>
          Get Started For Free
        </button>
        <button className="button outlined" onClick={handleContactClick}>
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default CTASection;
