import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CTASection from "./components/CTASection";
import DocumentDetail from "./components/DocumentDetail";
import "./App.css";
import Home from "./pages/Home";
import Features from "./pages/Features";
import AutomaticBankSync from "./pages/AutomaticBankSync";
import CustomizableBudgets from "./pages/CustomizableBudgets";
import TransactionCategorization from "./pages/TransactionCategorization";
import NetWorthOverview from "./pages/NetWorthOverview";
import Documents from "./pages/Documents";
import CashFlowAnalysis from "./pages/CashFlowAnalysis";
import PersonalizedDashboard from "./pages/PersonalizedDashboard";
import ManualTransactionEntry from "./pages/ManualTransactionEntry";
import DataImportFromMint from "./pages/DataImportFromMint";
import FlexibleDataImportOptions from "./pages/FlexibleDataImportOptions";
import ScheduledRecurringTransactions from "./pages/ScheduledRecurringTransactions";
import AutomatedTransactionRules from "./pages/AutomatedTransactionRules";
import UpcomingFeatures from "./pages/UpcomingFeatures";
import MobileApp from "./pages/MobileApp";
import Pricing from "./pages/Pricing";
import Security from "./pages/Security";
import Contact from "./pages/Contact";
import OurStory from "./pages/OurStory";
import Blog from "./pages/Blog";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/automaticbanksync" element={<AutomaticBankSync />} />
            <Route
              path="/customizablebudgets"
              element={<CustomizableBudgets />}
            />
            <Route
              path="/transactioncategorization"
              element={<TransactionCategorization />}
            />
            <Route path="/networthoverview" element={<NetWorthOverview />} />
            <Route path="/cashflowanalysis" element={<CashFlowAnalysis />} />
            <Route
              path="/personalizeddashboard"
              element={<PersonalizedDashboard />}
            />
            <Route
              path="/manualtransactionentry"
              element={<ManualTransactionEntry />}
            />
            <Route
              path="/dataimportfrommint"
              element={<DataImportFromMint />}
            />
            <Route
              path="/flexibledataimportoptions"
              element={<FlexibleDataImportOptions />}
            />
            <Route
              path="/scheduledrecurringtransactions"
              element={<ScheduledRecurringTransactions />}
            />
            <Route
              path="/automatedtransactionrules"
              element={<AutomatedTransactionRules />}
            />
            <Route path="/upcomingfeatures" element={<UpcomingFeatures />} />
            <Route path="/mobileapp" element={<MobileApp />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/security" element={<Security />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/documents" element={<Documents />} />{" "}
            <Route path="/documents/:docId" element={<DocumentDetail />} />{" "}
            <Route path="/ourstory" element={<OurStory />} />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/sitemap.xml"
              element={<Navigate to="/sitemap.xml" replace />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <CTASection />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
