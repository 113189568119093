import React from "react";

function Security() {
  return (
    <div className="security-container">
      <h1 className="title">Security</h1>
      <p className="security-intro">
        At Pachira, we take security seriously. Our platform employs the latest
        and most robust security measures to ensure that your data is always
        safe and protected. Here’s a detailed overview of our security practices
        and why they matter.
      </p>
      <div className="security-cards">
        <div className="security-card">
          <h3>SOC II Certification</h3>
          <p>
            We are actively aiming for SOC II certification. Achieving this
            certification demonstrates our commitment to maintaining high
            standards of security and data protection. SOC II compliance is
            crucial for the financial industry as it ensures that we adhere to
            stringent security policies and practices, providing assurance to
            our users that their data is safe and secure.
          </p>
        </div>
        <div className="security-card">
          <h3>End-to-End Encryption</h3>
          <p>
            Your data is encrypted from start to finish using advanced
            encryption standards. This ensures that only you and authorized
            parties can access your information, protecting it from unauthorized
            access during transmission and storage.
          </p>
        </div>
        <div className="security-card">
          <h3>Secure Database Storage</h3>
          <p>
            All data is stored in secure, protected databases that are regularly
            updated and maintained to prevent vulnerabilities. Our databases are
            equipped with state-of-the-art security features, including
            encryption at rest and access controls to ensure data integrity and
            confidentiality.
          </p>
        </div>
        <div className="security-card">
          <h3>Best Security Practices</h3>
          <p>
            We adhere to the best security practices in the industry. These
            practices include regular updates, patch management, and continuous
            monitoring to identify and mitigate potential security threats.
          </p>
        </div>
        <div className="security-card">
          <h3>Regular Security Audits</h3>
          <p>
            Our systems undergo frequent and thorough security audits by both
            internal teams and external third-party security experts. These
            audits help us identify vulnerabilities and ensure compliance with
            the latest security standards and regulations.
          </p>
        </div>
        <div className="security-card">
          <h3>Two-Factor Authentication (2FA)</h3>
          <p>
            We offer Two-Factor Authentication (2FA) to add an extra layer of
            security to your account. With 2FA, you need to provide two forms of
            identification before accessing your account, making it
            significantly harder for unauthorized users to gain access.
          </p>
        </div>
        <div className="security-card">
          <h3>Secure Password Requirements</h3>
          <p>
            We require users to create strong, secure passwords that include a
            mix of letters, numbers, and special characters. This helps protect
            your account from brute force attacks and unauthorized access.
          </p>
        </div>
        <div className="security-card">
          <h3>Continuous Monitoring</h3>
          <p>
            Our security team continuously monitors our systems for any unusual
            activity or potential threats. This proactive approach helps us
            detect and respond to security incidents in real-time, minimizing
            potential risks.
          </p>
        </div>
        <div className="security-card">
          <h3>Employee Training</h3>
          <p>
            All employees undergo regular security training to stay up-to-date
            with the latest security threats and best practices. This ensures
            that everyone at Pachira is equipped to protect your data and
            maintain a secure environment.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Security;
