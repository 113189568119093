import React from "react";
import icon from "../images/Pachira Icon vector.svg";
const Blog = () => {
  return (
    <div className="blog-container">
      <div className="blog-header">
        <h1>Our Blog is Coming Soon!</h1>
        <p>
          We are working hard to bring you an amazing financial education blog.
          Stay tuned for updates and exciting content to help you on your
          financial journey.
        </p>
      </div>
      <div className="blog-content">
        <img
          src={icon}
          alt="Under Construction"
          className="under-construction-image"
        />
        <p>
          In the meantime, why not join us on Pachira? Start your financial
          progress today with our comprehensive tools and resources.
        </p>
        <button
          className="button outlined top-bar-button"
          onClick={() =>
            window.open("https://app.pachira.abandm.com/register", "_blank")
          }
        >
          Join Pachira
        </button>
      </div>
    </div>
  );
};

export default Blog;
