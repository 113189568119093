// const BASE_URL = "http://localhost:5005/pdfs";
const BASE_URL = "https://api.pachira.abandm.com/pdfs";

export const policies = [
  {
    id: "Terms-of-Service",
    title: "Terms of Service",
    description: "Review the terms you agree to by using our service.",
    pdf: `${BASE_URL}/Terms-of-Service.pdf`,
  },
  {
    id: "Privacy-Policy",
    title: "Privacy Policy",
    description: "Understand how we handle your personal information.",
    pdf: `${BASE_URL}/Privacy-Policy.pdf`,
  },
  {
    id: "EULA",
    title: "End User License Agreement (EULA)",
    description:
      "Legal agreement between the user and the company regarding software usage.",
    pdf: `${BASE_URL}/EULA.pdf`,
  },
  {
    id: "Data-Sharing-Practices",
    title: "Data Sharing Practices and Automatic Consent",
    description: "Details on data sharing and automatic consent mechanisms.",
    pdf: `${BASE_URL}/Data-Sharing-Practices.pdf`,
  },
];

export const guides = [
  {
    id: "Quickstart-Guide",
    title: "Quickstart Guide",
    description:
      "Get started quickly with our product with this comprehensive guide.",
    pdf: `${BASE_URL}/Quickstart-Guide.pdf`,
  },
  {
    id: "Starting-Guide",
    title: "Starting Guide",
    description:
      "Initial steps to familiarize yourself with our interface and features.",
    pdf: `${BASE_URL}/Starting-Guide.pdf`,
  },
  {
    id: "Mobile-Install-Guide",
    title: "Mobile Install Guide",
    description: "Step-by-step instructions to install our mobile application.",
    pdf: `${BASE_URL}/Mobile-Install-Guide.pdf`,
  },
];
