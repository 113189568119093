import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import Image from "../images/transactions.png";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faTags,
  faEdit,
  faChartLine,
  faDownload,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

const feature = {
  title: "Transaction Categorization",
  subtitle: "Organize your transactions effortlessly",
  heroImage: Image,
  icon: faExchangeAlt,
  overview:
    "Easily organize your financial transactions into meaningful categories.",
  keyBenefits: [
    "Automatic transaction categorization",
    "Customizable categories",
    "Gain insights into spending habits",
    "Improve budget management",
    "Simplify financial tracking",
  ],
  steps: [
    {
      icon: faDownload,
      title: "Automatic Import",
      description: "Transactions are imported automatically.",
    },
    {
      icon: faTags,
      title: "Categorization",
      description: "Transactions are categorized.",
    },
    {
      icon: faChartLine,
      title: "Spending Reports",
      description: "Get detailed spending reports.",
    },
  ],
  testimonials: [
    {
      quote:
        "The automatic categorization of transactions has made managing my finances so much simpler. I love it!",
      author: "Sarah",
    },
    {
      quote:
        "Being able to customize categories is a game-changer. It helps me track my spending more accurately.",
      author: "Mark",
    },
  ],
  faqs: [
    {
      question: "How are transactions categorized?",
      answer:
        "Transactions are automatically categorized based on the merchant and transaction type. You can also customize categories to suit your needs.",
    },
    {
      question: "Can I recategorize transactions?",
      answer:
        "Yes, you can easily recategorize transactions by selecting a different category from the transaction details page.",
    },
  ],
};

const TransactionCategorization = () => {
  return <FeatureTemplate feature={feature} />;
};

export default TransactionCategorization;
