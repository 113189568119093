import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faChartLine,
  faCoins,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/dashboard.png";
const feature = {
  title: "Net Worth Overview",
  subtitle: "Get a comprehensive view of your financial health",
  heroImage: Image,
  icon: faChartLine,
  overview:
    "Track your net worth effortlessly with our comprehensive Net Worth Overview feature.",
  keyBenefits: [
    "Real-time net worth tracking",
    "Detailed breakdown of assets and liabilities",
    "Monitor financial health over time",
    "Spot trends in your finances",
  ],
  steps: [
    {
      icon: faCoins,
      title: "Connect All Your Accounts",
      description: "Link your bank accounts, investments, and assets.",
    },
    {
      icon: faSyncAlt,
      title: "Daily Data Refresh",
      description: "Your financial data is updated every day.",
    },
    {
      icon: faChartLine,
      title: "Track Net Worth Over Time",
      description: "Monitor changes in your net worth and financial health.",
    },
  ],
  testimonials: [
    {
      quote:
        "The Net Worth Overview feature gives me a clear picture of my financial health. It's incredibly useful.",
      author: "Emma",
    },
    {
      quote:
        "I love how I can track my assets and liabilities in one place. This feature has been a game-changer for me.",
      author: "Liam",
    },
  ],
  faqs: [
    {
      question: "How often is my net worth updated?",
      answer:
        "Your net worth is updated daily as you enter new data or sync with your financial accounts.",
    },
    {
      question: "Can I customize the categories for my assets and liabilities?",
      answer:
        "Yes, you can customize the categories to fit your personal financial situation.",
    },
  ],
};

const NetWorthOverview = () => {
  return <FeatureTemplate feature={feature} />;
};

export default NetWorthOverview;
