import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setStatusMessage("");

    const subject = `New contact form from ${formData.name} on Pachira website`;
    const text = `The following person sent a contact message from the Pachira landing website:\n\nName: ${formData.name}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`;

    try {
      const response = await fetch(
        "https://api.pachira.abandm.com/api/contact",
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subject, text, userEmail: formData.email }),
        }
      );

      if (response.ok) {
        setStatusMessage("Message sent successfully!");
        setIsSuccess(true);
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatusMessage(
        "Message failed to send. If this continues please contact support@pachira.abandm.com"
      );
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-content">
        <h2>Contact Us</h2>
        <p>
          Have questions or need support? We're here to help! Fill out the form
          below and we'll get back to you as soon as possible.
        </p>
        <p>
          Check out our <a href="/documents/Starting-Guide">Setup Guide</a> for
          easy directions to use the app.
        </p>
      </div>
      <div className="contact-form">
        {statusMessage && (
          <div className={`status-message ${isSuccess ? "success" : "error"}`}>
            {statusMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            required
            value={formData.name}
            onChange={handleChange}
            className="form-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            required
            value={formData.email}
            onChange={handleChange}
            className="form-input"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            required
            value={formData.message}
            onChange={handleChange}
            className="form-textarea"
          ></textarea>
          <button type="submit" className="form-button" disabled={isSubmitting}>
            {isSubmitting ? (
              <span>
                Sending Message<span className="dot-1">.</span>
                <span className="dot-2">.</span>
                <span className="dot-3">.</span>
              </span>
            ) : (
              "Send Message"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
