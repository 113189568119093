import React, { useEffect } from "react";

function Pricing() {
  const handleRegisterClick = () => {
    window.open("https://app.pachira.abandm.com/register", "_blank");
    window.scrollTo(0, 0);
  };

  const PayPalButton = () => {
    useEffect(() => {
      const scriptId = "paypal-donate-script-footer";
      const renderButton = () => {
        const donateButtonContainer = document.getElementById(
          "donate-button-footer"
        );
        if (
          window.PayPal &&
          donateButtonContainer &&
          !donateButtonContainer.firstChild
        ) {
          window.PayPal.Donation.Button({
            env: "production",
            hosted_button_id: "548WKU6TYAGVG",
            image: {
              src: "https://pics.paypal.com/00/s/NDFkYTNjODEtN2IxNS00YTJmLWE1MDYtYmY5YmNmNzJmZTg3/file.JPG",
              alt: "Donate with PayPal button",
              title: "PayPal - The safer, easier way to pay online!",
            },
          }).render("#donate-button-footer");
        }
      };

      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
        script.charset = "UTF-8";
        script.onload = renderButton;
        document.body.appendChild(script);
      } else {
        renderButton();
      }
    }, []);

    return (
      <div
        id="donate-button-footer"
        style={{ visibility: "hidden", position: "absolute" }}
      ></div>
    );
  };

  const handleDonateClick = () => {
    const donateButton = document.querySelector("#donate-button-footer img");
    if (donateButton) {
      donateButton.click();
    } else {
      console.error("PayPal donate button not found.");
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="pricing-container">
      <div className="info-card">
        <h1 className="info-card-title">Pricing</h1>
        <p className="info-card-text">
          We believe in providing powerful budgeting tools for free. We do not
          sell or share your data. Our mission is to help you manage your
          finances securely and effectively with peace of mind.
        </p>
        <p className="info-card-text">
          Our funding comes from minimal advertising, sponsorships, and
          community donations. This ensures Pachira remains free, secure, and
          continuously improved.
        </p>
      </div>

      <div className="card-container">
        {/* Free Plan */}
        <div className="paymentCard free-plan-card">
          <h3>Pachira Simplify Pass (Free)</h3>
          <h1 className="amount">$0</h1>
          <p>Enjoy all features with non-intrusive ads.</p>
          <p>
            <strong>Note:</strong> Free plans do not permit ad blockers.
          </p>
          <p>
            Pachira is completely free to use for everyone. There is no cost to
            use the app. We believe in providing financial tools to help you
            manage your budget without any financial burden.
          </p>
          <button
            className="button contained top-bar-button"
            onClick={handleRegisterClick}
          >
            Sign Up
          </button>
        </div>

        {/* Pachira Simplify Pass (Monthly) */}
        <div className="paymentCard monthly-plan-card">
          <h3>Pachira Simplify Pass (Monthly)</h3>
          <h1 className="amount">$2.50</h1>
          <p>per month for a completely ad-free experience</p>
          <p>
            <strong>Payment Type:</strong> Recurring
          </p>
          <p>
            After signing up you will need to purchase the pass under the "
            <strong>Settings</strong>" Menu
          </p>
          <button
            className="button contained top-bar-button"
            onClick={handleRegisterClick}
          >
            Sign Up
          </button>
        </div>

        {/* Pachira Simplify Pass (Yearly) */}
        <div className="paymentCard yearly-plan-card">
          <div className="discountTag">50% DISCOUNT</div>
          <h3>Pachira Simplify Pass (Yearly)</h3>
          <h1 className="amount">$15</h1>
          <p>per year for a completely ad-free experience</p>
          <p>
            <strong>Payment Type:</strong> Recurring
          </p>
          <p>
            After signing up you will need to purchase the pass under the "
            <strong>Settings</strong>" Menu
          </p>
          <button
            className="button contained top-bar-button"
            onClick={handleRegisterClick}
          >
            Sign Up
          </button>
        </div>

        {/* Donation */}
        <div className="paymentCard donation-card">
          <h3>Support Pachira</h3>
          <p>
            Your contributions help us keep the app free forever. Please donate
            to support our services and ensure we can continue providing free
            tools to manage your budget.
          </p>
          <div className="button-container">
            <PayPalButton />
            <button
              className="button contained top-bar-button"
              onClick={handleDonateClick}
            >
              Donate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
