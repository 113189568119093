import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const FeatureTemplate = ({ feature }) => {
  useEffect(() => {
    const sections = document.querySelectorAll(".feature-template-section");
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.classList.add("lazy-loaded");
        observer.unobserve(entry.target);
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.classList.add("lazy-loaded");
        observer.unobserve(entry.target);
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="feature-template-container">
      {/* Header Section */}
      <header className="feature-header feature-section">
        <div className="icon-container">
          <FontAwesomeIcon
            icon={feature.icon}
            className="feature-icon-header"
          />
        </div>
        <h1 className="title">{feature.title}</h1>
        <h2>{feature.subtitle}</h2>
      </header>
      <header className="feature-template-header feature-template-section">
        {feature.heroImage && (
          <img
            src={feature.heroImage}
            alt={feature.title}
            className="rounded-image limited-height"
          />
        )}
      </header>
      <button
        className="button outlined top-bar-button"
        onClick={() =>
          window.open("https://app.pachira.abandm.com/register", "_blank")
        }
      >
        Get Started
      </button>
      {/* Overview Section */}
      <section className="feature-template-cards feature-template-section">
        <div className="feature-template-cards-grid">
          <div className="feature-template-card text-container">
            <h3>Overview</h3>
            <p>{feature.overview}</p>
          </div>
        </div>
      </section>
      {/* Detailed Description Section */}
      <section className="feature-template-cards feature-template-section">
        <div className="feature-template-cards-grid">
          <div className="feature-template-card text-container how-to-card">
            <h3>How It Works</h3>
            <div className="flow-container">
              {feature.steps.map((step, index) => (
                <React.Fragment key={index}>
                  <div className="flow-step-wrapper">
                    <div className="flow-step">
                      <div className="step-content">
                        <FontAwesomeIcon
                          icon={step.icon}
                          className="flow-icon"
                        />
                        <h3>{step.title}</h3>
                        <p>{step.description}</p>
                      </div>
                    </div>
                  </div>
                  {index < feature.steps.length - 1 && (
                    <div className="arrow-wrapper">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="arrow-icon arrow-desktop"
                      />
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="arrow-icon arrow-mobile"
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Cards Section */}
      <section className="feature-template-cards feature-template-section">
        <div className="feature-template-cards-grid">
          <div className="feature-template-card text-container">
            <h3>Key Benefits</h3>
            <ul>
              {feature.keyBenefits.map((benefit, index) => (
                <li key={index}>
                  <i className="icon-check-circle"></i>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div className="feature-template-faqs-section">
            <section className="feature-template-faqs-container">
              <h3>FAQs</h3>
              {feature.faqs.map((faq, index) => (
                <div key={index} className="feature-template-faq">
                  <h4>{faq.question}</h4>
                  <p>{faq.answer}</p>
                </div>
              ))}
            </section>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <div className="feature-template-testimonials">
        <section className="feature-template-testimonials-section">
          <h2>Testimonials</h2>
          <div className="feature-template-testimonial-container">
            {feature.testimonials.map((testimonial, index) => (
              <div key={index} className={`feature-template-testimonial-card`}>
                <p>
                  "{testimonial.quote}" - {testimonial.author}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>

      {/* Footer Section */}
      <footer className="feature-template-footer feature-template-section">
        <h3>Ready to Use {feature.title}?</h3>
        <button
          className="button outlined top-bar-button"
          onClick={() =>
            window.open("https://app.pachira.abandm.com/register", "_blank")
          }
        >
          Sign Up Today
        </button>
        <p>
          <button
            className="button contained top-bar-button"
            onClick={() => window.open("/features", "_self")}
          >
            Explore Other Features
          </button>
        </p>
      </footer>
    </div>
  );
};

export default FeatureTemplate;
