import React, { useEffect } from "react";

function OurStory() {
  useEffect(() => {
    const sections = document.querySelectorAll(".our-story-section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );
    sections.forEach((section) => {
      observer.observe(section);
    });
  }, []);

  return (
    <div className="our-story-container">
      <section className="our-story-section our-story-intro">
        <div className="our-story-content">
          <h1 className="title">Our Story</h1>
          <p className="our-story-text">
            Welcome to Pachira! Thank you for choosing Pachira to embark on your
            journey toward financial literacy and empowerment. Whether you're
            here to fine-tune your budgeting skills or to start building a
            foundation for financial success, we're thrilled to support you
            every step of the way.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-origin">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">How It All Started</h2>
          <p className="our-story-text">
            In 2020, we were dissatisfied with the budgeting apps available.
            Some were too expensive, and the free ones lacked essential features
            like bank connections. We wanted something dynamic like Excel but
            cleaner and easier to use. This dissatisfaction led us to create
            Pachira.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-challenges">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">Facing Challenges</h2>
          <p className="our-story-text">
            We faced many challenges designing a platform that solved these
            problems. We reached out to the public for ideas on what budgeting
            apps were missing and how we could improve them. This feedback was
            crucial in shaping Pachira's features.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-development">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">Development Journey</h2>
          <p className="our-story-text">
            We started developing Pachira in 2023 and have gone through three
            different designs. The biggest push came when Mint announced they
            would be shutting down. The first version took three months, but we
            started over in August 2023 and released our first public beta in
            December 2023.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-vision">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">Our Vision</h2>
          <p className="our-story-text">
            Our vision for Pachira is to be more than a budgeting app. We want
            to provide the best financial education available to anyone,
            regardless of their income. Growing up, we learned about budgeting,
            saving, making good financial decisions, and avoiding common
            financial pitfalls. We want to share this knowledge with everyone.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-unique">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">What Makes Us Unique</h2>
          <p className="our-story-text">
            Pachira aims to fill the gap between poor-quality free budgeting
            apps and premium paid ones. We offer a high-quality app without
            regular subscriptions. Our team is made up of users who want to
            create something better for everyone. We use the app daily, ensuring
            we experience any bugs or inefficiencies just like our users.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-commitment">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">Our Commitment</h2>
          <p className="our-story-text">
            Pachira operates similarly to a non-profit. We plan to show only the
            necessary ads to keep the app running. All income from donations,
            ads, and sponsorships goes back into improving the app. Our goal is
            not to make anyone rich but to provide the best budgeting tool
            available.
          </p>
        </div>
      </section>

      <section className="our-story-section our-story-name">
        <div className="our-story-content">
          <h2 className="our-story-subtitle">What's in a Name?</h2>
          <p className="our-story-text">
            The name "Pachira" comes from the Pachira aquatica, commonly known
            as the money tree. If money could grow on trees, it would be great,
            but we hope to model the consistent, healthy, and stable growth of
            wealth so people can reap the benefits long term.
          </p>
        </div>
      </section>
    </div>
  );
}

export default OurStory;
