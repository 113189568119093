import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faUpload,
  faUniversity,
  faFileCsv,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/Import.png";
const feature = {
  title: "Flexible Data Import Options",
  subtitle: "Seamlessly integrate your financial data from various sources",
  heroImage: Image,
  icon: faUpload,
  overview:
    "Easily import your financial data from a variety of sources, including your bank, financial apps, and CSV files.",
  keyBenefits: [
    "Support for multiple data sources",
    "Quick and easy import process",
    "Ensure data integrity",
    "Seamless integration",
  ],
  steps: [
    {
      icon: faUniversity,
      title: "Connect Your Bank",
      description: "Integrate with your bank for direct data import.",
    },
    {
      icon: faFileCsv,
      title: "Upload CSV Files",
      description: "Import financial data using CSV files.",
    },
    {
      icon: faChartLine,
      title: "Utilize Imported Data",
      description: "Leverage your data for better financial insights.",
    },
  ],
  testimonials: [
    {
      quote:
        "Importing my financial data from different banks was a breeze. This feature saves me a lot of time.",
      author: "Anna",
    },
    {
      quote:
        "The flexibility to import data from Mint is fantastic. Highly recommend!",
      author: "Chris",
    },
  ],
  faqs: [
    {
      question: "What types of data sources can I import from?",
      answer: "You can import data from banks, financial apps, and CSV files.",
    },
    {
      question:
        "How do I ensure my data remains secure during the import process?",
      answer:
        "Our platform uses advanced encryption and security protocols to ensure your data remains secure during the import process.",
    },
    {
      question: "Is the import process automated?",
      answer:
        "Yes, the import process can be automated for bank connections and supported financial apps. Manual imports via CSV are also available.",
    },
  ],
};

const FlexibleDataImportOptions = () => {
  return <FeatureTemplate feature={feature} />;
};

export default FlexibleDataImportOptions;
