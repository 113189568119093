import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faMobileAlt,
  faDownload,
  faSyncAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/mobile.png";
const feature = {
  title: "Mobile App",
  subtitle: "Access your finances on the go",
  heroImage: Image,
  icon: faMobileAlt,
  overview: (
    <>
      Manage your finances anytime, anywhere with the Pachira Mobile App. Enjoy
      comprehensive financial management, real-time updates, and secure access,
      all from your mobile device. For detailed installation instructions,
      please visit the{" "}
      <a
        href="https://pachira.abandm.com/documents/Mobile-Install-Guide"
        target="_blank"
        rel="noopener noreferrer"
      >
        Mobile App Guide
      </a>
      .
    </>
  ),
  keyBenefits: [
    "Comprehensive financial management",
    "On-the-go access",
    "Real-time updates and notifications",
    "Secure and private",
  ],
  steps: [
    {
      icon: faDownload,
      title: "Download the App",
      description: (
        <>
          Get the app on your device. Follow the{" "}
          <a
            href="https://pachira.abandm.com/documents/Mobile-Install-Guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mobile App Guide
          </a>{" "}
          for detailed instructions.
        </>
      ),
    },
    {
      icon: faSyncAlt,
      title: "Real-Time Sync",
      description: "Keep your financial data updated in real-time.",
    },
    {
      icon: faShieldAlt,
      title: "Secure Access",
      description: "Manage your finances securely from your device.",
    },
  ],
  testimonials: [
    {
      quote:
        "The mobile app makes it so easy to manage my finances on the go. Highly recommended!",
      author: "Anna",
    },
    {
      quote:
        "I love having access to all the features on my phone. The app is very user-friendly.",
      author: "Chris",
    },
  ],
  faqs: [
    {
      question: "How do I install the mobile app?",
      answer: (
        <>
          Visit our website on your Android device and follow the instructions
          here{" "}
          <a
            href="https://pachira.abandm.com/documents/Mobile-Install-Guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mobile App Guide
          </a>
          .
        </>
      ),
    },
    {
      question: "Is the mobile app secure?",
      answer:
        "Yes, our mobile app uses advanced encryption and privacy protection to ensure your data remains secure.",
    },
    {
      question: "Can I use the app on Apple devices?",
      answer:
        "Currently, the Pachira app is not available for Apple devices. You can use Safari to log in through the web page.",
    },
  ],
};

const MobileApp = () => {
  return <FeatureTemplate feature={feature}></FeatureTemplate>;
};

export default MobileApp;
