import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faUniversity,
  faLock,
  faSyncAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";

import Image from "../images/bank.png";
const feature = {
  title: "Automatic Bank Sync",
  subtitle: "Seamlessly connect your bank accounts",
  heroImage: Image,
  icon: faUniversity,
  overview:
    "Connect your bank accounts to keep your financial status updated automatically. With Yodlee's secure connections, your data is always safe and current.",
  keyBenefits: [
    "Instant access to updated financial data",
    "Reliable and secure connections through Yodlee",
    "Bank-level security for peace of mind",
    "Streamlined financial management",
  ],
  steps: [
    {
      icon: faSyncAlt,
      title: "Secure Connection",
      description: "Connect using Yodlee's trusted platform.",
    },
    {
      icon: faUniversity,
      title: "Real-Time Updates",
      description: "Get updates on your account daily",
    },
    {
      icon: faLock,
      title: "Bank-Level Security",
      description: "Your information is always protected.",
    },
  ],
  testimonials: [
    {
      quote:
        "Connecting my bank accounts was a breeze, and now I get real-time updates on my finances.",
      author: "Alice",
    },
    {
      quote:
        "The security of Yodlee gives me peace of mind. I know my data is safe.",
      author: "Bob",
    },
    {
      quote:
        "It's so convenient to have all my financial information in one place.",
      author: "Charlie",
    },
  ],
  faqs: [
    {
      question: "How do I connect my bank accounts?",
      answer:
        "Simply go to the Accounts page and follow the instructions to securely connect your bank accounts through Yodlee.",
    },
    {
      question: "Is my financial data secure?",
      answer:
        "Absolutely. We use Yodlee's bank-level security to ensure your financial data is always protected.",
    },
    {
      question: "Does Pachira store my bank credentials?",
      answer:
        "No, Pachira does not store or even see your bank credentials. The connection is handled entirely through Yodlee's secure system.",
    },
  ],
};

const AutomaticBankSync = () => {
  return <FeatureTemplate feature={feature} />;
};

export default AutomaticBankSync;
