import React from "react";

function NotFound() {
  return (
    <div className="our-story-container">
      <section className="our-story-section our-story-intro visible">
        <div className="our-story-content">
          <h1 className="title">404 - Page Not Found</h1>
          <p className="our-story-text">
            The page you are looking for does not exist. Please check the URL or
            return to the homepage.
          </p>
        </div>
      </section>
    </div>
  );
}

export default NotFound;
