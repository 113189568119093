import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { policies, guides } from "../docs";

const DocumentManager = () => {
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.style.opacity = "1";
        entry.target.style.transform = "translateY(0)";
        observer.unobserve(entry.target);
      });
    }, options);

    sections.forEach((section) => {
      section.style.opacity = "0";
      section.style.transform = "translateY(20px)";
      section.style.transition =
        "opacity 0.5s ease-out, transform 0.5s ease-out";
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="document-manager">
      <h1>Documents</h1>
      <nav>
        <a href="#policies">Policies</a> | <a href="#guides">Guides</a>
      </nav>
      <section id="policies">
        <h2>Policies</h2>
        <ul>
          {Object.values(policies).map((doc) => (
            <li key={doc.id}>
              <Link to={`/documents/${doc.id}`}>
                <strong>{doc.title}</strong>
                <p>{doc.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <section id="guides">
        <h2>Guides</h2>
        <ul>
          {Object.values(guides).map((doc) => (
            <li key={doc.id}>
              <Link to={`/documents/${doc.id}`}>
                <strong>{doc.title}</strong>
                <p>{doc.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default DocumentManager;
