import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUniversity,
  faPiggyBank,
  faExchangeAlt,
  faChartLine,
  faMoneyBillWave,
  faHome,
  faKeyboard,
  faLeaf,
  faUpload,
  faRepeat,
  faPenRuler,
  faMobileAlt,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

const features = [
  {
    title: "Automatic Bank Sync",
    description:
      "Sync your bank accounts automatically to keep your financial data up-to-date.",
    path: "/automaticbanksync",
    icon: faUniversity,
  },
  {
    title: "Customizable Budgets",
    description: "Create and manage budgets tailored to your financial goals.",
    path: "/customizablebudgets",
    icon: faPiggyBank,
  },
  {
    title: "Transaction Categorization",
    description:
      "Automatically categorize your transactions for easy tracking.",
    path: "/transactioncategorization",
    icon: faExchangeAlt,
  },
  {
    title: "Net Worth Overview",
    description:
      "Get a comprehensive view of your net worth, including assets and liabilities.",
    path: "/networthoverview",
    icon: faChartLine,
  },
  {
    title: "Cash Flow Analysis",
    description:
      "Analyze your income and expenses to understand your cash flow better.",
    path: "/cashflowanalysis",
    icon: faMoneyBillWave,
  },
  {
    title: "Personalized Dashboards",
    description:
      "Customize your dashboard to display the information that matters most to you.",
    path: "/personalizeddashboard",
    icon: faHome,
  },
  {
    title: "Manual Transactions",
    description: "Manually enter transactions to keep your records accurate.",
    path: "/manualtransactionentry",
    icon: faKeyboard,
  },
  {
    title: "Data Import from Mint",
    description: "Easily import your financial data from Mint to Pachira.",
    path: "/dataimportfrommint",
    icon: faLeaf,
  },
  {
    title: "Flexible Data Import Options",
    description:
      "Import your financial data in various formats for convenience.",
    path: "/flexibledataimportoptions",
    icon: faUpload,
  },
  {
    title: "Scheduled Recurring Transactions",
    description:
      "Schedule recurring transactions to stay on top of your finances.",
    path: "/scheduledrecurringtransactions",
    icon: faRepeat,
  },
  {
    title: "Automated Transaction Rules",
    description:
      "Set up rules to automate transaction categorization and management.",
    path: "/automatedtransactionrules",
    icon: faPenRuler,
  },
  {
    title: "Mobile App",
    description: "Access your financial data on the go with our mobile app.",
    path: "/mobileapp",
    icon: faMobileAlt,
  },
  {
    title: "Upcoming Features",
    description:
      "View all upcoming features and give your feedback on what can be added to Pachira.",
    path: "/upcomingfeatures",
    icon: faPlusCircle,
  },
];

function Features() {
  const navigate = useNavigate();
  const handleCardClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="features-container">
      <header className="feature-header">
        <h1 className="title">Features Overview</h1>
      </header>
      <div className="features-list">
        {features.map((feature, index) => (
          <div
            key={index}
            className="feature-card"
            onClick={() => handleCardClick(feature.path)}
          >
            <div className="feature-gradient"></div>
            <FontAwesomeIcon
              icon={feature.icon}
              className="feature-icon-large"
            />
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
            <div className="feature-link">Learn More &rarr;</div>
          </div>
        ))}
      </div>
      <hr />
      <div className="feedback-section feature-card plain-background">
        <h2>We value your feedback</h2>
        <p>
          We highly encourage you to contact our support team for any queries or
          feedback. This can be done easily by clicking the support button in
          the app—whether it's to report a bug, request a feature, provide
          design input, or other needs. Your feedback is invaluable as it helps
          us enhance your experience and the functionality of Pachira.
        </p>
        <button
          className="button outlined top-bar-button"
          onClick={() => {
            navigate("/contact");
            window.scrollTo(0, 0);
          }}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default Features;
