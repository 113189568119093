import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const upcomingFeatures = [
  {
    title: "Investment Tracking",
    description:
      "See portfolios across all accounts for a comprehensive financial picture.",
  },
  {
    title: "Wealth Planning Tools",
    description:
      "Understand current finances and future potential with our planning tools.",
  },
  {
    title: "Retirement Planning Tools",
    description:
      "Plan for retirement and end-of-life financial goals effectively.",
  },
  {
    title: "Virtual Accounts for Money Management",
    description:
      "Manage income by distributing it into different accounts for budgeting.",
  },
  {
    title: "New Widgets for User Dashboard",
    description:
      "Suggest new widgets like graphs, reports, charts, and metrics for the dashboard.",
  },
  {
    title: "Dark Theme",
    description:
      "A new dark theme to reduce eye strain and provide a sleek look.",
  },
];

function UpcomingFeatures() {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="features-container">
      <header className="feature-header">
        <h1 className="title">Upcoming Features</h1>
      </header>
      <div className="features-list">
        {upcomingFeatures.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-gradient"></div>
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <hr />
      <div className="feedback-section feature-card plain-background">
        <h2>We value your feedback</h2>
        <p>
          We highly encourage you to contact our support team for any queries or
          feedback. This can be done easily by clicking the support button in
          the app—whether it's to report a bug, request a feature, provide
          design input, or other needs. Your feedback is invaluable as it helps
          us enhance your experience and the functionality of Pachira.
        </p>
        <button
          className="button outlined top-bar-button"
          onClick={handleCardClick}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default UpcomingFeatures;
