import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import { faPenRuler, faCogs, faClock } from "@fortawesome/free-solid-svg-icons";
import Image from "../images/transactions.png";

const feature = {
  title: "Automated Transaction Rules",
  subtitle: "Automate your financial management",
  heroImage: Image,
  icon: faPenRuler,
  overview:
    "Take control of your finances with our Automated Transaction Rules. Set up custom rules to automatically categorize and manage your transactions, making financial management effortless and precise.",
  keyBenefits: [
    "Automatically categorize transactions",
    "Set up custom rules for different transactions",
    "Reduce manual entry and errors",
    "Improve accuracy and efficiency",
    "Be as hands-on or hands-off as you like",
  ],
  steps: [
    {
      icon: faPenRuler,
      title: "Create Rules",
      description: "Set custom rules.",
    },
    {
      icon: faCogs,
      title: "Categorize",
      description: "Rules categorize transactions.",
    },
    {
      icon: faClock,
      title: "Save Time",
      description: "Reduce manual entry.",
    },
  ],
  testimonials: [
    {
      quote:
        "This feature has saved me so much time! My transactions are always correctly categorized without any manual effort.",
      author: "Emily",
    },
    {
      quote:
        "Setting up rules was easy, and now I never have to worry about miscategorized expenses.",
      author: "Michael",
    },
    {
      quote:
        "I love how I can be as hands-on or hands-off as I want. Budgeting has never been this easy!",
      author: "Sophia",
    },
  ],
  faqs: [
    {
      question: "How do I set up automated transaction rules?",
      answer:
        "Navigate to the Transactions page and select 'Transaction Rules'. From there, you can create new rules based on your preferences. You can also add rules directly from a transaction for quick setup.",
    },
    {
      question: "How do automated rules help me manage my budget?",
      answer:
        "Automated rules categorize your transactions for you, reducing the time you spend on manual entry. This ensures accuracy and helps you stay on top of your finances effortlessly.",
    },
    {
      question: "Can I control how my transactions are categorized?",
      answer:
        "Yes, you have full control over the rules you set. You can start with suggested rules and then switch to automatic as you become more comfortable.",
    },
  ],
};

const AutomatedTransactionRules = () => {
  return <FeatureTemplate feature={feature} />;
};

export default AutomatedTransactionRules;
