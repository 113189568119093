import React, { useEffect } from "react";
import Image from "../images/dashboard.png";
import bankImage from "../images/bankCrop.png";
import budgetImage from "../images/budget.png";
import dashImage from "../images/incomeVsExpenses.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faDollarSign,
  faInfoCircle,
  faHome,
  faUniversity,
  faPiggyBank,
  faLock,
  faCog,
  faChartBar,
  faClock,
  faLightbulb,
  faBullseye,
  faUserFriends,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.classList.add("lazy-loaded");
        observer.unobserve(entry.target);
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  const handleDonateClick = () => {
    const donateButton = document.querySelector("#donate-button-footer img");
    if (donateButton) {
      donateButton.click();
    } else {
      console.error("PayPal donate button not found.");
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.classList.add("lazy-loaded");
        observer.unobserve(entry.target);
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  useEffect(() => {
    const testimonials = document.querySelectorAll(".testimonial-card");
    let currentIndex = 0;

    const updateTestimonials = () => {
      testimonials.forEach((testimonial, index) => {
        testimonial.classList.remove(
          "active",
          "left",
          "right",
          "offscreen-left",
          "offscreen-right"
        );
        if (index === currentIndex) {
          testimonial.classList.add("active");
        } else if ((index + 1) % testimonials.length === currentIndex) {
          testimonial.classList.add("left");
        } else if (
          (index - 1 + testimonials.length) % testimonials.length ===
          currentIndex
        ) {
          testimonial.classList.add("right");
        } else if (
          (index - 2 + testimonials.length) % testimonials.length ===
          currentIndex
        ) {
          testimonial.classList.add("offscreen-left");
        } else if ((index + 2) % testimonials.length === currentIndex) {
          testimonial.classList.add("offscreen-right");
        }
      });
      currentIndex = (currentIndex + 1) % testimonials.length;
    };

    const interval = setInterval(updateTestimonials, 3000);

    return () => clearInterval(interval);
  }, []);
  const PayPalButton = () => {
    useEffect(() => {
      const scriptId = "paypal-donate-script-footer";
      const renderButton = () => {
        const donateButtonContainer = document.getElementById(
          "donate-button-footer"
        );
        if (
          window.PayPal &&
          donateButtonContainer &&
          !donateButtonContainer.firstChild
        ) {
          window.PayPal.Donation.Button({
            env: "production",
            hosted_button_id: "548WKU6TYAGVG",
            image: {
              src: "https://pics.paypal.com/00/s/NDFkYTNjODEtN2IxNS00YTJmLWE1MDYtYmY5YmNmNzJmZTg3/file.JPG",
              alt: "Donate with PayPal button",
              title: "PayPal - The safer, easier way to pay online!",
            },
          }).render("#donate-button-footer");
        }
      };

      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
        script.charset = "UTF-8";
        script.onload = renderButton;
        document.body.appendChild(script);
      } else {
        renderButton();
      }
    }, []);

    return (
      <div
        id="donate-button"
        style={{ visibility: "hidden", position: "absolute" }}
      ></div>
    );
  };

  return (
    <div>
      <section className="hero">
        <div className="text-container">
          <h1>
            <span className="grow-wealth">Grow Wealth,</span>
            <span className="simplify-life">Simplify Life</span>
          </h1>
          <h2>Completely free and packed with powerful budgeting tools.</h2>
          <img src={Image} alt="Pachira on devices" className="rounded-image" />
        </div>
        <button
          className="button outlined top-bar-button"
          onClick={() =>
            window.open("https://app.pachira.abandm.com/register", "_blank")
          }
        >
          Get Started
        </button>
      </section>

      <section className="about-us ">
        <div className="small-text-container">
          <h2>About Us</h2>
          <p className="about-us-text">
            Welcome to Pachira! Started in 2020, our mission is to provide
            powerful and user-friendly budgeting tools and education for
            individuals and families, empowering them to achieve financial
            literacy and stability.
          </p>
          <button
            className="button contained top-bar-button"
            onClick={() => window.open("/ourstory", "_blank")}
          >
            Learn More
          </button>
        </div>
      </section>

      <section className="features-overview">
        <div className="text-container">
          <h2>Features Overview</h2>
          <div className="feature">
            <div className="feature-text">
              <h3>
                <FontAwesomeIcon icon={faUniversity} className="feature-icon" />
                Automatic Bank Connections
              </h3>
              <p>
                Effortlessly sync your bank accounts for real-time updates and
                seamless budgeting. Enjoy the convenience of automatic
                synchronization without manual entry, ensuring your financial
                data is always current and secure. Your privacy is our top
                priority.
              </p>

              <button
                className="button outlined top-bar-button"
                onClick={() => window.open("/automaticbanksync", "_blank")}
              >
                Learn More
              </button>
            </div>
            <div className="floating-image-container">
              <img
                src={bankImage}
                alt="Automatic Bank Connections"
                className="floating-image floating-image-left"
              />{" "}
            </div>
          </div>
          <div className="feature">
            <div className="feature-text">
              <h3>
                <FontAwesomeIcon icon={faPiggyBank} className="feature-icon" />
                Flexible Budgets
              </h3>
              <p>
                Create and adjust budgets that fit your lifestyle and financial
                goals. With our flexible budgeting tools, you can easily track
                your spending, identify areas to save, and plan for the future.
              </p>
              <button
                className="button outlined top-bar-button"
                onClick={() => window.open("/customizablebudgets", "_blank")}
              >
                Learn More
              </button>
            </div>
            <div className="floating-image-container">
              <img
                src={budgetImage}
                alt="Flexible Budgets"
                className="floating-image floating-image-right"
              />{" "}
            </div>
          </div>
          <div className="feature">
            <div className="feature-text">
              <h3>
                <FontAwesomeIcon icon={faHome} className="feature-icon" />
                Informative Dashboards
              </h3>
              <p>
                Visualize your financial data with our comprehensive and
                customizable dashboards. Gain insights at a glance with
                intuitive charts and graphs, and customize your view to focus on
                what matters most to you.
              </p>
              <button
                className="button outlined top-bar-button"
                onClick={() => window.open("/personalizeddashboard", "_blank")}
              >
                Learn More
              </button>
            </div>
            <div className="floating-image-container">
              <img
                src={dashImage}
                alt="Informative Dashboards"
                className="floating-image floating-image-left"
              />{" "}
            </div>
          </div>
          <div className="highlight-container">
            <h1 className="highlight-text">
              All features are available to you completely free of charge!
            </h1>
            <p className="highlight-description">
              We believe in providing powerful budgeting tools for free. We do
              not sell or share your data. Our mission is to help you manage
              your finances securely and effectively.
              <br />
              <a href="/pricing" className="highlight-link">
                Discover How We Keep It Free and Secure
              </a>
            </p>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="container">
          <h2>How It Works</h2>
          <div className="flow-container">
            <div className="flow-step">
              <FontAwesomeIcon icon={faLock} className="flow-icon" />
              <h3>Securely connect your bank accounts</h3>
              <p>
                Pachira connects to your bank accounts using advanced encryption
                and security protocols.
              </p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            <div className="flow-step">
              <FontAwesomeIcon icon={faCog} className="flow-icon" />
              <h3>Create and customize budgets</h3>
              <p>
                Set up flexible budgets that fit your lifestyle and financial
                goals.
              </p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            <div className="flow-step">
              <FontAwesomeIcon icon={faChartBar} className="flow-icon" />
              <h3>Visualize and track your financial health</h3>
              <p>
                Comprehensive dashboards provide insights into your financial
                data.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="benefits">
        <div className="container">
          <h2>Benefits</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <FontAwesomeIcon icon={faClock} className="benefit-icon" />
              <h3>Save time with automation</h3>
              <p>
                Automate your transactions and budgeting to save time and reduce
                manual effort.
              </p>
            </div>
            <div className="benefit-card">
              <FontAwesomeIcon icon={faLightbulb} className="benefit-icon" />
              <h3>Gain financial insights</h3>
              <p>
                Use data-driven insights to make informed financial decisions.
              </p>
            </div>
            <div className="benefit-card">
              <FontAwesomeIcon icon={faBullseye} className="benefit-icon" />
              <h3>Achieve financial goals</h3>
              <p>
                Set and achieve your financial goals with personalized planning
                tools.
              </p>
            </div>
            <div className="benefit-card">
              <FontAwesomeIcon icon={faUserFriends} className="benefit-icon" />
              <h3>User-friendly design and accessibility</h3>
              <p>
                Enjoy a user-friendly design that is accessible to everyone.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div>
        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial-container">
            <div className="testimonial-card active">
              <p>
                "Pachira has transformed how I manage my finances. The automatic
                bank connections and flexible budgets make everything so easy!"
                - Sarah
              </p>
            </div>
            <div className="testimonial-card">
              <p>
                "I love how informative the dashboards are. I can see all my
                financial data at a glance." - John
              </p>
            </div>
            <div className="testimonial-card">
              <p>
                "Finally, a free budgeting app that doesn't compromise on
                features. Highly recommended!" - Emily
              </p>
            </div>
            <div className="testimonial-card">
              <p>
                "Pachira's insights have helped me save more and spend wisely.
                It's like having a personal financial advisor!" - Mike
              </p>
            </div>
            <div className="testimonial-card">
              <p>
                "The flexibility in budgeting and real-time updates make Pachira
                indispensable for managing my finances." - Lisa
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Major Pages Links Section */}
      <section className="major-pages">
        <div className="page-link text-container left">
          <div className="text">
            <h3>Security</h3>
            <p>
              Discover how we protect your data with advanced security features
              like encryption and secure login.
            </p>
            <button
              className="button contained top-bar-button"
              onClick={() => window.open("/security", "_blank")}
            >
              Learn More
            </button>
          </div>
          <FontAwesomeIcon icon={faShieldAlt} className="opaque-icon" />
        </div>
        <div className="page-link text-container right">
          <div className="text">
            <h3>Pricing</h3>
            <p>
              Learn how Pachira is free for everyone, keeps your data private,
              and never sells your information.
            </p>
            <button
              className="button contained top-bar-button"
              onClick={() => window.open("/pricing", "_blank")}
            >
              Learn More
            </button>
          </div>
          <FontAwesomeIcon icon={faDollarSign} className="opaque-icon" />
        </div>
        <div className="page-link text-container left">
          <div className="text">
            <h3>About Us</h3>
            <p>
              Learn about our mission to provide top-notch budgeting tools and
              our vision for a financially literate world.
            </p>
            <button
              className="button contained top-bar-button"
              onClick={() => window.open("/ourstory", "_blank")}
            >
              Learn More
            </button>
          </div>
          <FontAwesomeIcon icon={faInfoCircle} className="opaque-icon" />
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-content">
          <div className="faq-item">
            <h3>Is Pachira really free?</h3>
            <p>
              Yes, Pachira is completely free with no hidden costs. We do not
              sell your data.
            </p>
          </div>
          <div className="faq-item">
            <h3>How do I connect my bank accounts?</h3>
            <p>
              Simply sign up and click the + icon on the top right of your
              dashboard to securely connect your bank accounts.
            </p>
          </div>
          <div className="faq-item">
            <h3>Is my data secure?</h3>
            <p>
              Absolutely. We use advanced encryption and security measures to
              protect your data in transit and at rest.
            </p>
          </div>
          <div className="faq-item">
            <h3>Can I customize my budget?</h3>
            <p>
              Yes, Pachira allows you to create and adjust budgets that fit your
              financial goals.
            </p>
          </div>
          <div className="faq-item">
            <h3>How often is my financial data updated?</h3>
            <p>
              Your financial data is updated daily as your bank updates the
              infomation.
            </p>
          </div>
          <div className="faq-item">
            <h3>Can I import my data from other budgeting apps?</h3>
            <p>
              Yes, Pachira supports data import from several popular budgeting
              apps like Mint, ensuring a seamless transition.
            </p>
          </div>
        </div>
      </section>

      {/* Donation */}
      <div className="paymentCard donation-card">
        <h3>Support Pachira</h3>
        <p>
          Your contributions help us keep the app free forever. Please donate to
          support our services and ensure we can continue providing free tools
          to manage your budget.
        </p>
        <div className="button-container">
          <PayPalButton />
          <button
            className="button contained top-bar-button"
            onClick={handleDonateClick}
          >
            Donate
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
