import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faLeaf,
  faDownload,
  faUpload,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/mintImport.png";
const dataImportFromMintFeature = {
  title: "Data Import from Mint",
  subtitle: "Seamlessly transition your financial data",
  heroImage: Image,
  icon: faLeaf,
  overview: `
    Transitioning from Mint to Pachira is easy and secure. Our tool ensures all your financial data 
    is transferred accurately and safely. Start managing your finances with Pachira without any hassle.
  `,
  keyBenefits: [
    "Easy setup and transition",
    "Secure and encrypted data transfer",
    "Keep your financial history",
    "No data loss during migration",
    "Quick and efficient process",
  ],
  steps: [
    {
      icon: faDownload,
      title: "Download Your Data",
      description: "Export your financial data from Mint.",
    },
    {
      icon: faUpload,
      title: "Upload to Pachira",
      description: "Import your data securely into Pachira.",
    },
    {
      icon: faCheckCircle,
      title: "Enjoy Seamless Management",
      description: "Manage your finances without interruption.",
    },
  ],
  testimonials: [
    {
      quote:
        "The data import feature was a lifesaver. Transitioning from Mint was effortless!",
      author: "Emily",
    },
    {
      quote:
        "I love how smooth the process was. All my data was transferred perfectly.",
      author: "Michael",
    },
    {
      quote:
        "Switching to Pachira was the best decision. The import tool made it so easy!",
      author: "Sophia",
    },
    {
      quote:
        "I was worried about losing data, but everything was transferred flawlessly.",
      author: "David",
    },
  ],
  faqs: [
    {
      question: "How do I start the data import?",
      answer:
        "Go to the 'Import Data' section on the transaction page and follow the on-screen instructions. It's quick and easy.",
    },
    {
      question: "Is my data secure during the import?",
      answer:
        "Yes, we use advanced encryption to keep your data safe during the transfer.",
    },
    {
      question: "What if I encounter issues?",
      answer:
        "Our support team is available 24/7 to help you with any problems you might have. We’re here to assist!",
    },
    {
      question: "How does this feature help me?",
      answer:
        "The data import tool ensures all your financial information is moved over to Pachira without any hassle. This means you can continue managing your finances seamlessly.",
    },
  ],
};

function DataImportFromMint() {
  return <FeatureTemplate feature={dataImportFromMintFeature} />;
}

export default DataImportFromMint;
