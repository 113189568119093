import React, { useEffect } from "react";
import icon from "../images/Pachira Icon vector.svg";
import logo from "../images/Pachira Vector Black.svg";

function Footer() {
  const FooterPayPalButton = () => {
    useEffect(() => {
      const scriptId = "paypal-donate-script-footer";
      const renderButton = () => {
        const donateButtonContainer = document.getElementById(
          "donate-button-footer"
        );
        if (
          window.PayPal &&
          donateButtonContainer &&
          !donateButtonContainer.firstChild
        ) {
          window.PayPal.Donation.Button({
            env: "production",
            hosted_button_id: "548WKU6TYAGVG",
            image: {
              src: "https://pics.paypal.com/00/s/NDFkYTNjODEtN2IxNS00YTJmLWE1MDYtYmY5YmNmNzJmZTg3/file.JPG",
              alt: "Donate with PayPal button",
              title: "PayPal - The safer, easier way to pay online!",
            },
          }).render("#donate-button-footer");
        }
      };

      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
        script.charset = "UTF-8";
        script.onload = renderButton;
        document.body.appendChild(script);
      } else {
        renderButton();
      }
    }, []);

    return (
      <div
        id="donate-button-footer"
        style={{ visibility: "hidden", position: "absolute" }}
      ></div>
    );
  };

  const handleDonateClick = () => {
    const donateButton = document.querySelector("#donate-button-footer img");
    if (donateButton) {
      donateButton.click();
    } else {
      console.error("PayPal donate button not found.");
    }
  };

  const menuItems = [
    {
      section: "Quick Links",
      items: [
        { text: "Features", path: "/features" },
        { text: "Mobile App", path: "/mobileapp" },
        { text: "Security", path: "/security" },
        { text: "Pricing", path: "/pricing" },
        { text: "Policies and Guides", path: "/documents" },
        { text: "Automatic Bank Sync", path: "/automaticbanksync" },
        { text: "Customizable Budgets", path: "/customizablebudgets" },
        {
          text: "Transaction Categorization",
          path: "/transactioncategorization",
        },
        { text: "Net Worth Overview", path: "/networthoverview" },
        { text: "Cash Flow Analysis", path: "/cashflowanalysis" },
        { text: "Personalized Dashboards", path: "/personalizeddashboard" },
        { text: "Manual Transactions", path: "/manualtransactionentry" },
        { text: "Data Import from Mint", path: "/dataimportfrommint" },
        {
          text: "Flexible Data Import Options",
          path: "/flexibledataimportoptions",
        },
        {
          text: "Scheduled Recurring Transactions",
          path: "/scheduledrecurringtransactions",
        },
        {
          text: "Automated Transaction Rules",
          path: "/automatedtransactionrules",
        },

        { text: "Go to App", path: "https://app.pachira.abandm.com/login" },
      ],
    },
    {
      section: "Company",
      items: [
        { text: "Our Story", path: "/ourstory" },
        { text: "Blog", path: "/blog" },
      ],
    },
    {
      section: "Legal",
      items: [
        {
          text: "Terms of Service",
          path: "/documents/Terms-of-Service",
        },
        {
          text: "Privacy Policy",
          path: "/documents/Privacy-Policy",
        },
        { text: "Documents", path: "/documents" },
      ],
    },
  ];

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section support">
          <a href="/" className="logo-link">
            <img src={icon} alt="Pachira Logo" className="icon" />
          </a>
          <p>Grow Wealth, Simplify Life</p>
          <h4>Support Pachira</h4>
          <p>
            Your contributions help us keep the app free forever. Please donate
            to support our services and ensure we can continue providing free
            tools to manage your budget.
          </p>
          <div className="button-container">
            <FooterPayPalButton />
            <button
              className="button outlined top-bar-button"
              onClick={handleDonateClick}
            >
              Donate
            </button>
          </div>
          <div className="footer-section contact">
            <p>Have questions or need support? We're here to help!</p>
            <button
              className="button outlined top-bar-button"
              onClick={() => {
                window.location.href = "/contact";
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className="footer-section quick-links">
          <h4>Quick Links</h4>
          <ul>
            {menuItems[0].items.map((item, index) => (
              <li key={index}>
                <a href={item.path}>{item.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-section menu-columns">
          {menuItems.slice(1).map((menu, index) => (
            <div key={index} className="footer-subsection">
              <h4>{menu.section}</h4>
              <ul>
                {menu.items.map((item, index) => (
                  <li key={index}>
                    <a href={item.path}>{item.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-bottom">
        <div>
          <a href="/" className="logo-link">
            <img src={logo} alt="Pachira Logo" className="logo" />
          </a>
        </div>
        <p>&copy; 2024 Pachira. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
