import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faKeyboard,
  faTags,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/manualTransaction.png";
const feature = {
  title: "Manual Transactions",
  subtitle: "Easily add and manage your transactions manually",
  heroImage: Image,
  icon: faKeyboard,
  overview:
    "Our Manual Transactions feature lets you add and manage transactions that aren't linked to your bank accounts. This is perfect for those who prefer not to connect their bank directly or need to record cash transactions.",
  keyBenefits: [
    "Full control over your data",
    "Add transactions not linked to accounts",
    "Customize categories and tags",
    "Ensure accuracy in your financial records",
  ],

  steps: [
    {
      icon: faKeyboard,
      title: "Add Transaction",
      description: "Manually input any transaction.",
    },
    {
      icon: faTags,
      title: "Categorize Transaction",
      description: "Assign it to a category.",
    },
    {
      icon: faChartLine,
      title: "Complete Financial Picture",
      description: "Enjoy having your finances in one place.",
    },
  ],
  testimonials: [
    {
      quote:
        "The manual transactions feature is a lifesaver. I can easily add cash transactions and keep my records accurate.",
      author: "Anna",
    },
    {
      quote:
        "I love the flexibility of being able to add transactions manually. It helps me keep track of everything.",
      author: "Jake",
    },
  ],
  faqs: [
    {
      question: "How do I add a manual transaction?",
      answer:
        "Navigate to the transactions page and click on 'Add Transaction'. Fill in the details and save.",
    },
    {
      question: "Can I edit or delete a manual transaction?",
      answer:
        "Yes, you can edit or delete any manual transaction from the transactions page.",
    },
  ],
};

const ManualTransactionEntry = () => {
  return <FeatureTemplate feature={feature} />;
};

export default ManualTransactionEntry;
