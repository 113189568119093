import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUniversity,
  faExchangeAlt,
  faPiggyBank,
  faRepeat,
  faMoneyBillWave,
  faChartLine,
  faFileContract,
  faClipboard,
  faUpload,
  faMobileAlt,
  faPlusCircle,
  faKeyboard,
  faPenRuler,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../images/Pachira Vector Black.svg";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setDropdownOpen(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  const handleGoToAppClick = () => {
    window.gtag("event", "conversion_event_outbound_click", {
      event_callback: function () {},
      event_timeout: 2000,
    });
    window.open("https://app.pachira.abandm.com/login", "_blank");
    handleDrawerClose();
  };

  const menuItems = [
    {
      text: "Features",
      path: "/features",
      dropdown: [
        {
          text: "Automatic Bank Sync",
          path: "/automaticbanksync",
          icon: faUniversity,
        },
        {
          text: "Customizable Budgets",
          path: "/customizablebudgets",
          icon: faPiggyBank,
        },
        {
          text: "Transaction Categorization",
          path: "/transactioncategorization",
          icon: faExchangeAlt,
        },
        {
          text: "Net Worth Overview",
          path: "/networthoverview",
          icon: faChartLine,
        },
        {
          text: "Cash Flow Analysis",
          path: "/cashflowanalysis",
          icon: faMoneyBillWave,
        },
        {
          text: "Personalized Dashboards",
          path: "/personalizeddashboard",
          icon: faHome,
        },
        {
          text: "Manual Transactions",
          path: "/manualtransactionentry",
          icon: faKeyboard,
        },
        {
          text: "Data Import from Mint",
          path: "/dataimportfrommint",
          icon: faLeaf,
        },
        {
          text: "Flexible Data Import Options",
          path: "/flexibledataimportoptions",
          icon: faUpload,
        },
        {
          text: "Scheduled Recurring Transactions",
          path: "/scheduledrecurringtransactions",
          icon: faRepeat,
        },
        {
          text: "Automated Transaction Rules",
          path: "/automatedtransactionrules",
          icon: faPenRuler,
        },
        { text: "Mobile App", path: "/mobileapp", icon: faMobileAlt },
        {
          text: "Upcoming Features",
          path: "/upcomingfeatures",
          icon: faPlusCircle,
        },
      ],
    },
    { text: "Security", path: "/security" },
    { text: "Pricing", path: "/pricing" },
    { text: "Contact", path: "/contact" },
    {
      text: "Policies and Guides",
      path: "/documents",
      dropdown: [
        { text: "Policies", header: true },
        {
          text: "Terms of Service",
          path: "/documents/Terms-of-Service",
          icon: faFileContract,
        },
        {
          text: "Privacy Policy",
          path: "/documents/Privacy-Policy",
          icon: faFileContract,
        },
        {
          text: "End User License Agreement (EULA)",
          path: "/documents/EULA",
          icon: faFileContract,
        },
        {
          text: "Data Sharing Practices",
          path: "/documents/Data-Sharing-Practices",
          icon: faFileContract,
        },
        { text: "Guides", header: true },
        {
          text: "Quickstart Guide",
          path: "/documents/Quickstart-Guide",
          icon: faClipboard,
        },
        {
          text: "Starting Guide",
          path: "/documents/Starting-Guide",
          icon: faClipboard,
        },
        {
          text: "Mobile Install Guide",
          path: "/documents/Mobile-Install-Guide",
          icon: faClipboard,
        },
      ],
    },
    {
      text: "About Us",
      path: "/ourstory",
      dropdown: [
        { text: "Our Story", path: "/ourstory" },
        { text: "Blog", path: "/blog" },
      ],
    },
  ];

  const renderPoliciesAndGuidesDropdown = (item) => {
    const policies = [];
    const guides = [];
    let isGuideSection = false;

    item.dropdown.forEach((subItem) => {
      if (subItem.header && subItem.text === "Guides") {
        isGuideSection = true;
      } else if (isGuideSection) {
        guides.push(subItem);
      } else if (subItem.text === "Policies") {
      } else {
        policies.push(subItem);
      }
    });

    return (
      <div className="dropdown-policies-guides">
        <div className="dropdown-column">
          <h3 className="dropdown-header">Policies</h3>
          {policies.map((subItem, index) => (
            <button
              key={index}
              className="dropdown-item"
              onClick={() => handleNavigation(subItem.path)}
            >
              {subItem.text}
            </button>
          ))}
        </div>
        <div className="dropdown-column">
          <h3 className="dropdown-header">Guides</h3>
          {guides.map((subItem, index) => (
            <button
              key={index}
              className="dropdown-item"
              onClick={() => handleNavigation(subItem.path)}
            >
              {subItem.text}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <header className="topbar">
        <div className="toolbar">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Pachira Logo" className="logo" />
          </Link>
          <div className="menu-items">
            {menuItems.map((item) => (
              <div
                key={item.text}
                className="menu-button-container"
                onMouseEnter={() => setDropdownOpen(item.text)}
                onMouseLeave={() => setDropdownOpen(null)}
              >
                <button
                  className="menu-button"
                  onClick={() => handleNavigation(item.path)}
                >
                  {item.icon && (
                    <FontAwesomeIcon icon={item.icon} className="nav-icon" />
                  )}
                  {item.text}
                </button>
                {dropdownOpen === item.text && item.dropdown && (
                  <div
                    className={`dropdown ${
                      item.text === "Features"
                        ? "dropdown-features"
                        : item.text === "Policies and Guides"
                        ? "dropdown-policies-guides"
                        : item.text === "About Us"
                        ? "dropdown-about"
                        : ""
                    }`}
                  >
                    {item.dropdown && item.text === "Policies and Guides" ? (
                      <>
                        <div
                          className="menu-button-container"
                          onMouseEnter={() => setDropdownOpen(item.text)}
                          onMouseLeave={() => setDropdownOpen(null)}
                        >
                          {dropdownOpen === item.text &&
                          item.dropdown &&
                          item.text === "Policies and Guides"
                            ? renderPoliciesAndGuidesDropdown(item)
                            : dropdownOpen === item.text &&
                              item.dropdown && (
                                <div
                                  className={`dropdown ${
                                    item.text === "Features"
                                      ? "dropdown-features"
                                      : "dropdown-about"
                                  }`}
                                >
                                  {item.dropdown.map((subItem, index) => (
                                    <div
                                      key={index}
                                      className="dropdown-column"
                                    >
                                      {subItem.header ? (
                                        <h3 className="dropdown-header">
                                          {subItem.text}
                                        </h3>
                                      ) : (
                                        <button
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleNavigation(subItem.path)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={subItem.icon}
                                            className="nav-icon"
                                          />
                                          {subItem.text}
                                        </button>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                        </div>
                      </>
                    ) : (
                      item.dropdown.map((subItem, index) => (
                        <div key={index} className="dropdown-column">
                          {subItem.header ? (
                            <h3 className="dropdown-header">{subItem.text}</h3>
                          ) : (
                            <button
                              className="dropdown-item"
                              onClick={() => handleNavigation(subItem.path)}
                            >
                              <FontAwesomeIcon
                                icon={subItem.icon}
                                className="nav-icon"
                              />
                              {subItem.text}
                            </button>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="buttons">
            <button
              className="button outlined top-bar-button"
              onClick={() =>
                window.open("https://app.pachira.abandm.com/register", "_blank")
              }
            >
              Get Started
            </button>
            <button
              className="button contained top-bar-button"
              onClick={handleGoToAppClick}
            >
              Go to App
            </button>
          </div>
          <button className="menu-icon" onClick={handleDrawerOpen}>
            <MenuIcon />
          </button>
        </div>
      </header>
      <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <button onClick={handleDrawerClose} className="close-icon">
            <CloseIcon />
          </button>
        </div>
        <ul className="drawer-menu">
          {menuItems.map((item) => (
            <li
              key={item.text}
              onClick={() =>
                item.path
                  ? handleNavigation(item.path)
                  : setDropdownOpen(item.text)
              }
            >
              {item.icon && (
                <FontAwesomeIcon icon={item.icon} className="nav-icon" />
              )}
              {item.text}
            </li>
          ))}
          <li>
            <button
              className="button outlined top-bar-button"
              onClick={() =>
                window.open("https://app.pachira.abandm.com/register", "_blank")
              }
            >
              Get Started For Free
            </button>
          </li>
          <li>
            <button
              className="button contained top-bar-button"
              onClick={handleGoToAppClick}
            >
              Go to App
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
