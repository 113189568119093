import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faMoneyBillWave,
  faChartLine,
  faSearchDollar,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

import Image from "../images/cashFlow.png";
const feature = {
  title: "Cash Flow Analysis",
  subtitle: "Gain insights into your financial health",
  heroImage: Image,
  icon: faMoneyBillWave,
  overview:
    "Understand your cash flow with detailed analysis and insights. Our Cash Flow Analysis tool helps you make informed financial decisions by tracking your income and expenses.",
  keyBenefits: [
    "Real-time cash flow tracking",
    "Identify spending patterns",
    "Optimize your budget",
    "Plan for future expenses",
  ],
  steps: [
    {
      icon: faTags,
      title: "Categorize Transactions",
      description: "Sort your income and expenses.",
    },
    {
      icon: faChartLine,
      title: "Analyze Expenses",
      description: "Identify spending patterns.",
    },
    {
      icon: faSearchDollar,
      title: "Optimize Budget",
      description: "Plan for future expenses.",
    },
  ],
  testimonials: [
    {
      quote:
        "Cash Flow Analysis has transformed the way I manage my finances. I can now see exactly where my money is going.",
      author: "Emily",
    },
    {
      quote:
        "This tool is invaluable for budgeting and planning future expenses. Highly recommend!",
      author: "Michael",
    },
    {
      quote:
        "Being able to visualize my cash flow has helped me save more effectively.",
      author: "Sophia",
    },
    {
      quote:
        "The real-time tracking feature is a game-changer. I always know my financial status.",
      author: "David",
    },
  ],
  faqs: [
    {
      question: "How does Cash Flow Analysis work?",
      answer:
        "The tool tracks your income and expenses in real-time, providing insights and patterns to help you optimize your budget and plan for the future.",
    },
    {
      question: "Can I customize the analysis?",
      answer:
        "You can customize the time range for the analysis to fit your unique financial situation.",
    },
    {
      question: "How does it help me visualize my savings and spending?",
      answer:
        "The cash flow diagram shows all your income sources on the left and all your expenses on the right, helping you see whether you are saving or spending more.",
    },
  ],
};

const CashFlowAnalysis = () => {
  return <FeatureTemplate feature={feature} />;
};

export default CashFlowAnalysis;
