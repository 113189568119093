import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faPiggyBank,
  faEdit,
  faChartPie,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

import Image from "../images/budgetPage.png";
const CustomizableBudgets = () => {
  const featureData = {
    title: "Customizable Budgets",
    subtitle: "Tailor your budgets to fit your unique financial goals",
    heroImage: Image,
    icon: faPiggyBank,
    overview: "Create and manage budgets that align with your financial goals.",
    keyBenefits: [
      "Easy to set up and adjust",
      "Track your spending in real-time",
      "Get insights and recommendations",
      "Flexible time frame settings",
      "Organize expenses into categories",
    ],
    steps: [
      {
        icon: faEdit,
        title: "Create Budgets",
        description: "Set up budgets easily.",
      },
      {
        icon: faChartPie,
        title: "Track Spending",
        description: "Monitor expenses in real-time.",
      },
      {
        icon: faLightbulb,
        title: "Plan Better",
        description: "Make informed financial decisions.",
      },
    ],
    testimonials: [
      {
        quote: "These budgets have helped me save more money each month!",
        author: "Alex",
      },
      {
        quote: "I love how easy it is to adjust my budgets as my needs change.",
        author: "Emily",
      },
      {
        quote:
          "It's great to have all my personal categories organized and easy to manage.",
        author: "Michael",
      },
    ],
    faqs: [
      {
        question: "How do I set up a customizable budget?",
        answer:
          "Go to the budget section, click 'Create Budget', and follow the prompts.",
      },
      {
        question: "Can I track my spending in real-time?",
        answer:
          "Yes, you can track your spending in real-time with our customizable budget feature.",
      },
      {
        question: "Can I edit or delete budget categories?",
        answer: "Yes, you can edit or delete categories at any time.",
      },
    ],
  };

  return <FeatureTemplate feature={featureData} />;
};

export default CustomizableBudgets;
