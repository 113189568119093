import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import { faHome, faCog, faChartLine } from "@fortawesome/free-solid-svg-icons";
import Image from "../images/dashboard.png";
const feature = {
  title: "Personalized Dashboards",
  subtitle: "Tailor your financial insights to your needs",
  heroImage: Image,
  icon: faHome,
  overview:
    "Customize your financial dashboard to display the information most relevant to you. Gain insights at a glance and stay on top of your finances with ease.",
  keyBenefits: [
    "Customizable widgets",
    "Real-time financial insights",
    "User-friendly interface",
    "Tailored to your needs",
  ],
  steps: [
    {
      icon: faHome,
      title: "Access Dashboard",
      description: "Go to your personalized dashboard.",
    },
    {
      icon: faCog,
      title: "Customize Widgets",
      description: "Add or remove widgets to suit your needs.",
    },
    {
      icon: faChartLine,
      title: "Gain Insights",
      description: "View real-time financial data tailored to you.",
    },
  ],
  testimonials: [
    {
      quote:
        "The personalized dashboard is amazing! I can see all my important financial info in one place.",
      author: "Emily",
    },
    {
      quote:
        "Being able to customize my dashboard has made managing my finances so much easier.",
      author: "Michael",
    },
  ],
  faqs: [
    {
      question: "How do I customize my dashboard?",
      answer: "Navigate to the Tile Editor to turn on and off tiles.",
    },
    {
      question: "Are the dashboard updates in real-time?",
      answer:
        "Yes, the dashboard provides real-time updates to ensure you always have the latest financial insights.",
    },
  ],
};

const PersonalizedDashboard = () => {
  return <FeatureTemplate feature={feature} />;
};

export default PersonalizedDashboard;
