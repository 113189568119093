import React from "react";
import FeatureTemplate from "../components/FeatureTemplate";
import {
  faRepeat,
  faCalendarAlt,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";
import Image from "../images/recurringTransactions.png";
const feature = {
  title: "Scheduled Recurring Transactions",
  subtitle: "Automate your finances effortlessly",
  heroImage: Image,
  icon: faRepeat,
  overview:
    "Experience the ultimate convenience with our Scheduled Recurring Transactions feature. Automate your finances, ensure timely payments, and keep your financial records up to date with ease.",
  keyBenefits: [
    "Effortless automation of regular payments",
    "Never miss a bill payment again",
    "Minimize manual data entry",
    "Stay on top of your finances with up-to-date records",
    "Receive transaction suggestions based on your history",
  ],
  steps: [
    {
      icon: faRepeat,
      title: "Add Recurring Transactions",
      description: "Easily add all your recurring transactions.",
    },
    {
      icon: faCalendarAlt,
      title: "Track with Calendar",
      description: "Use the calendar to keep track of due dates.",
    },
    {
      icon: faBalanceScale,
      title: "Monitor Your Balance",
      description: "Ensure you have enough funds for upcoming payments.",
    },
  ],
  testimonials: [
    {
      quote:
        "Automating my recurring bills has saved me so much time and hassle. I never have to worry about missing a payment.",
      author: "Alex",
    },
    {
      quote:
        "This feature has made managing my finances so much easier. Highly recommend!",
      author: "Jordan",
    },
  ],
  faqs: [
    {
      question: "How does it help me track my bills?",
      answer:
        "Our Scheduled Recurring Transactions feature keeps track of your regular payments, so you always know when a bill is due. It helps you ensure you have enough funds in your account to cover upcoming payments.",
    },
    {
      question: "Can it pay bills for me?",
      answer:
        "No, the feature does not directly pay bills for you. However, it helps you remember when payments are due and ensures you stay on top of your financial obligations by tracking your scheduled transactions.",
    },
  ],
};

const ScheduledRecurringTransactions = () => {
  return <FeatureTemplate feature={feature} />;
};

export default ScheduledRecurringTransactions;
