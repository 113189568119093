import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { policies, guides } from "../docs";

const DocumentDetail = () => {
  const Loading = () => {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading document...</p>
      </div>
    );
  };

  const { docId } = useParams();
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const foundDocument =
      policies.find((doc) => doc.id === docId) ||
      guides.find((doc) => doc.id === docId);

    if (foundDocument) {
      setDocument(foundDocument);
    }
    setLoading(false);
  }, [docId]);

  if (loading) {
    return <Loading />;
  }

  if (!document) {
    return <div>Document not found</div>;
  }

  return (
    <div className="text-container">
      <div className="document-detail">
        <h1>{document.title}</h1>
        <p>{document.description}</p>
        {loading ? (
          <Loading />
        ) : (
          <iframe
            src={`${document.pdf}#zoom=100`}
            title={document.title}
            width="100%"
            height="1200px"
          />
        )}
      </div>
    </div>
  );
};

export default DocumentDetail;
